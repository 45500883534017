import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";

import {
  IconBellRingNotifications,
  IconCloudDataSync,
  IconCreditCardPaymentBilling,
  IconGlobe,
  IconInsuranceHandShieldProtection,
  IconLogOutExitCLoseLeaveArrow,
  IconPersonPhysicianProfileStethoscope,
  IconProfilePersonAvatarPlaceholderHeadBody,
  IconShippingPackageBox,
  InfoCircleIcon
} from "src/constants/icons";
import AppVersionBloc from "src/hybrid/AppVersionBloc";
import { OpenBrowser } from "src/hybrid/components/Browser";
import translate from "src/lib/translate";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import UserCubit from "src/state/UserCubit/UserCubit";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";
import Avatar from "src/ui/components/Avatar/Avatar";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import Translate from "../Translate/Translate";
import HealthSyncBloc from "src/state/HealthSyncBloc/HealthSyncBloc";

export const PROFILE_URL = "/app/profile";

const PageWrap = styled.div`
  padding: 0 1.6rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0 0;
  padding: 0;

  .content {
    margin-left: 1rem;
  }
`;

export enum ProfileMenuItem {
  account = "/account",
  billing = "/billing",
  dataSync = "/data-sync",
  dataSyncLearnMore = "/data-sync-learn-more",
  notifications = "/notifications",
  shipping = "/shipping",
  insurance = "/insurance",
  pharmacy = "/pharmacy",
  pcp = "/primary-care-physician",
  language = "/language"
}

const ProfileOverview: FC = () => {
  const [, { displayName, loadUserPreferences }] =
    useBloc(UserPreferencesCubit);
  const [{ userData }] = useBloc(UserCubit);
  const [, { logout }] = useBloc(AuthenticationBloc);
  const [, { currentVersionString }] = useBloc(AppVersionBloc);
  const [{ available }] = useBloc(HealthSyncBloc);

  useEffect(() => {
    void loadUserPreferences();
  }, []);

  const openHelpCenter = () => {
    const url = "https://help.9am.health/hc/en-us";
    void OpenBrowser(url, {
      useBaseUrl: false,
      presentationStyle: "popover"
    });
  };

  const handleClick = async () => {
    await logout();
  };

  return (
    <>
      <PageWrap>
        <nine-spacer s="sm"></nine-spacer>
        <Header>
          <Avatar border edit id={userData?.id} />
          <div className="content">
            <div className="name as-subhead as-strong m0">{displayName}</div>
            <div className="email as-body m0 color-c-80">{userData?.email}</div>
          </div>
        </Header>
        <Collection>
          <CollectionItemLink
            icon={<IconProfilePersonAvatarPlaceholderHeadBody />}
            label={translate("accountInformation")}
            to={PROFILE_URL + ProfileMenuItem.account}
          />
          <CollectionItemLink
            icon={<IconCreditCardPaymentBilling />}
            label={translate("billingAndPayment")}
            to={PROFILE_URL + ProfileMenuItem.billing}
          />
          {available && (
            <CollectionItemLink
              icon={<IconCloudDataSync />}
              label={translate("dataSync")}
              to={PROFILE_URL + ProfileMenuItem.dataSync}
            />
          )}
          <CollectionItemLink
            icon={<IconGlobe />}
            label={translate("language")}
            to={PROFILE_URL + ProfileMenuItem.language}
          />
          <CollectionItemLink
            icon={<IconBellRingNotifications />}
            label={translate("notifications")}
            to={PROFILE_URL + ProfileMenuItem.notifications}
          />
          <CollectionItemLink
            icon={<IconShippingPackageBox />}
            label={translate("shippingInformation")}
            to={PROFILE_URL + ProfileMenuItem.shipping}
          />
        </Collection>

        <Collection>
          <CollectionItemLink
            icon={<IconInsuranceHandShieldProtection />}
            label={translate("insuranceInformation")}
            to={PROFILE_URL + ProfileMenuItem.insurance}
          />
          <CollectionItemLink
            icon={<IconPersonPhysicianProfileStethoscope />}
            label={translate("primaryCarePhysician")}
            to={PROFILE_URL + ProfileMenuItem.pcp}
          />
        </Collection>

        <Collection>
          <CollectionItemLink
            icon={<InfoCircleIcon />}
            label={translate("helpCenter")}
            onClick={openHelpCenter}
          />
        </Collection>

        <Collection>
          <CollectionItemLink
            icon={<IconLogOutExitCLoseLeaveArrow />}
            label={translate("logout")}
            onClick={() => void handleClick()}
          />
        </Collection>

        <nine-spacer s="sm"></nine-spacer>
        <div className="as-little color-c-60">
          <Translate
            msg="version"
            variables={{ version: currentVersionString }}
          />
        </div>
        <nine-spacer s="lg"></nine-spacer>
      </PageWrap>
    </>
  );
};

export default ProfileOverview;
